import React, { useEffect, useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { UpperIcon } from "../../assets/common-svg/CommonSvg";
import PropTypes from "prop-types";
import { FormHelperText, InputLabel, TextField, useTheme } from "@mui/material";

export default function CustomDropdown({
    Width,
    TextSize,
    MenuList,
    MinWidth,
    handleChange,
    Height,
    value,
    LargeBorderRadius,
    ShowLargeDropdown,
    DropDownlabel,
    DropDownlabelInput,
    PaddingY,
    PlaceholderText,
    touched,
    error,
    helperText,
    InputId,
    IsRequired,
    disabled,
    multiple = false,
    showSelectAll = false,
    grouping = false
}) {
    const [isOpen, setIsOpen] = React.useState(false);
    const menuRef = useRef(null);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [totalCount, setTotalCount] = React.useState(0)
    const theme = useTheme();

    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleSearchChange = (event) => {
        if (menuRef.current) {
            menuRef.current.scrollTop = 0;

            // Brief timeout to ensure the scroll reset is applied after the menu re-renders
            setTimeout(() => {
                if (menuRef.current) {
                    menuRef.current.scrollTop = 0;
                }
            }, 0);
        }
        setSearchTerm(event.target.value);
    };
    const handleClose = () => {
        setIsOpen(false);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const CustomIconComponent = (props) => {
        return isOpen ? (
            <div className="cursor-pointer rotate-180 m-[1.3px] pl-2 " {...props}>
                <UpperIcon />
            </div>
        ) : (
            <div className="cursor-pointer m-[1.3px] pr-2" {...props}>
                <UpperIcon />
            </div>
        );
    };

    const borderRadiusClass = LargeBorderRadius ? "rounded-[10px]" : "rounded-[6px]";
    const minWidthClass = MinWidth ? `md:min-w-[${MinWidth ? 200 : 230}px]` : "md:min-w-[230px]";
    const combinedClassName = `${borderRadiusClass} min-w-0 w-full ${minWidthClass} border border-gray-200`;

    useEffect(() => {
        if(grouping){
            setTotalCount(MenuList.reduce((acc, item) => acc + item.questions.length, 0))
        }
    },[]);

    const filteredMenuList =  grouping
        ? MenuList.map(form => ({
            ...form,
            questions: form.questions.filter(question =>
                question.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        }))
        : MenuList;

    const handleSelectChange = (event) => {
        const { value } = event.target;
        const currentMenuList = grouping ? filteredMenuList.flatMap(form => form.questions) : MenuList;
    
        if (multiple) {
            // Handle "Select All" logic
            if (value.includes('select-all')) {
                const allSelected = value.length === currentMenuList.length + 1; // +1 for "Select All"
                const newSelectedValues = allSelected ? [] : currentMenuList.map((item) => item.id);
                handleChange(newSelectedValues);
    
                // Reset scroll position to the top after updating the value
                if (menuRef.current) {
                    menuRef.current.scrollTop = 0;
    
                    setTimeout(() => {
                        if (menuRef.current) {
                            menuRef.current.scrollTop = 0;
                        }
                    }, 0);
                }
            } else {
                handleChange(typeof value === 'string' ? value.split(',') : value);
            }
        } else {
            handleChange(value);
        }
    };

    // Map selected IDs to their names for displaying
    const renderSelectedValues = (selected) => {
        // If no value is selected, return the placeholder text
        if (!selected || selected.length === 0) return PlaceholderText;
    
        // Flatten MenuList if grouping is true
        const allItems = grouping
        ? MenuList.flatMap((form) => form.questions || [])  // Flatten questions from all forms into a single array
        : MenuList;
        return selected
            .map((id) => {
                const item = allItems.find((menuItem) => menuItem.id === id);
                return item ? item.name : "";  // Return item name or empty string if not found
            })
            .filter((name) => name)  // Filter out empty strings or undefined names
            .join(", ");
    };
    

    const MenuProps = {
        PaperProps: {
            ref: menuRef, // Reference to the menu to control scrolling
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
    };

    const isSelectAllSelected = value?.length === (
        grouping
        ? totalCount
        : MenuList?.length
    );   
    return (
        <div className="flex flex-col relative">
            {DropDownlabel ? (
                <InputLabel htmlFor={"entitlement"} className="text-current">
                    {DropDownlabel}
                    {IsRequired ? <span className="text-red-500">*</span> : ""}
                </InputLabel>
            ) : null}
            <FormControl
                className={combinedClassName}
                sx={{
                    flexGrow: 1,
                    width: Width ?? "",
                    backgroundColor: disabled ? theme.palette.grey[200] : "white",
                    color: disabled ? theme.palette.primary.light : "black",
                }}
            >
                {DropDownlabelInput && (
                    <InputLabel
                        className="text-[#828282] font-normal text-base mt-[-6px]"
                        id="demo-simple-select-helper-label"
                    >
                        {DropDownlabelInput}
                    </InputLabel>
                )}
                <Select
                    value={multiple ? (value || []) : (value || "")}
                    onChange={handleSelectChange}
                    disabled={disabled}
                    color="error"
                    // onKeyDown={handleKeyDown}  // Add onKeyDown event handler
                    sx={{
                        paddingY: PaddingY,
                        height: Height,
                        "& fieldset": { border: "none" },
                        ".MuiSelect-icon": {
                            top: "calc(50% - 1.3em)",
                        },
                        ".MuiSelect-select": {
                            color: value?.length > 0 ? "#000000" : "#777777"
                        },
                        textTransform:"capitalize"
                    }}
                    MenuProps={MenuProps}
                    placeholder="enter the text"
                    className={`text-["red"] font-[roboto] h-[44px] ${ShowLargeDropdown && !Height ? "h-[41px]" : null}`}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    IconComponent={CustomIconComponent}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    multiple={multiple}
                    renderValue={multiple ? renderSelectedValues : undefined}
                >
                    
                    {grouping && <MenuItem disableRipple>
                            <TextField
                                autoFocus
                                size="small"
                                fullWidth
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                onClick={(event) => event.stopPropagation()} // Prevents the default click behavior
                                onKeyDown={(event) => event.stopPropagation()} // Prevents the default click behavior
                                // onKeyUp={(event) => event.stopPropagation()} // Prevents the default click behavior
                                sx={{ mb: 1 }}
                            />
                        </MenuItem>}

                        {showSelectAll && (
                        <MenuItem value="select-all">
                            <Checkbox
                                checked={isSelectAllSelected}
                                indeterminate={value.length > 0 && !isSelectAllSelected}
                            />
                            <ListItemText primary="Select All" />
                        </MenuItem>
                    )}
                    {grouping
                        ? filteredMenuList.map((form) => {
                            if (Array.isArray(form.questions) && form.questions.length > 0) {
                                return [
                                    <ListItemText
                                        primary={form.title}
                                        sx={{ paddingLeft: "16px", paddingTop: "8px", fontWeight: "bold" }}
                                        key={`group-${form.id}`}
                                    />,
                                    ...form.questions.map((item) => (
                                        <MenuItem
                                            className={`text-[${TextSize}] capitalize`}
                                            value={item.id}
                                            key={item.id}
                                        >
                                            {multiple && (
                                                <Checkbox
                                                    checked={Array.isArray(value) && value.indexOf(item.id) > -1}
                                                />
                                            )}
                                            <ListItemText primary={item.name} />
                                        </MenuItem>
                                    )),
                                ];
                            }
                            return null;
                        }).flat()
                        : MenuList?.map((list) => (
                            <MenuItem
                                className={`text-[${TextSize}] capitalize`}
                                value={list.id}
                                key={list.id}
                            >
                                {multiple && (
                                    <Checkbox checked={Array.isArray(value) && value.indexOf(list.id) > -1} />
                                )}
                                <ListItemText primary={list.name} />
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            {touched && error && (
                <FormHelperText error id={`standard-weight-helper-text-${InputId}`} className="absolute bottom-[-23px]">
                    {helperText}
                </FormHelperText>
            )}
        </div>
    );
}

CustomDropdown.propTypes = {
    TextSize: PropTypes.string,
    Width: PropTypes.string,
    MenuList: PropTypes.array.isRequired, // Ensure MenuList is required
    handleChange: PropTypes.func.isRequired, // Ensure handleChange is required
    handleOpen: PropTypes.func,
    handleClose: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired, // Ensure value is required
    isOpen: PropTypes.bool,
    ShowLargeDropdown: PropTypes.bool,
    ShowLabel: PropTypes.bool,
    PlaceholderText: PropTypes.string,
    HideBorderBoolean: PropTypes.bool,
    LargeBorderRadius: PropTypes.bool,
    multiple: PropTypes.bool, // Added to propTypes
    Height: PropTypes.string,
    PaddingY: PropTypes.string,
    touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    error: PropTypes.bool,
    helperText: PropTypes.string,
    InputId: PropTypes.string,
    IsRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    showSelectAll: PropTypes.bool,
};

CustomDropdown.defaultProps = {
    multiple: false, // Default value for multiple
};



