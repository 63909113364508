import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import CustomTable from "../../components/custom-table/CustomTable";
import { subjectData } from "../../utils/tableData";
import PISubjectApi from "../../services/coordinatingPiSubject";
import SubAdminApi from "../../services/subAdminService.js";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar";
import { setSnackbar } from "../../store/reducers/snackBar";
import { useDispatch, useSelector } from "react-redux";
import { addPiSubjectValues, ReAssignPiEditor, reAssignValues } from "../../utils/formik/initialValues";
import { piSubjectValidationSchema, reAssignPiValidationSchema, ReAssignValidationSchema } from "../../utils/formik/validationSchema";
import CustomModel from "../../components/custom-model/CustomModel";
import moment from "moment";
import ProductApi from "../../services/productService";
import AddPiAndEditorForAllModalContent from "../../components/modal-contents/AddPiAndEditorForAllModalContent .js";

const convertPISubjectData = (data,curr_page, page_details, order ) => {
  return data?.map((sub, index) => {
    let SerialNo = order=='desc'? (page_details?.no_of_records-((curr_page-1)*10))-index : index+1+((curr_page-1)*10);
    let transformPatientId = sub?.patient_id || "--";
    let transformedEditor = sub?.first_name + " " + sub?.last_name || "";
    let transformedEditorName = sub?.editorDetails?.name||"Not Assigned";
    let transformedPatientStatus = sub?.life_status == 'lost' ? 'Lost To Follow Up': sub?.life_status;
    let transformedLastVisitType = sub?.last_visit_type||"--"
    let transformePhase = sub?.phase_name;
    let transformedGender = sub?.gender;
    let transformCycleCount = sub?.cycle
    let transformedStatus =
      sub?.status_details?.status.charAt(0).toUpperCase() +
      sub?.status_details?.status.slice(1);
      let transformedProduct = sub?.productDetails?.name;
      let transformedId = sub?.id;
      let transformedPhaseId = sub?.phase_id
      let lastAppointmentDate = sub?.last_appointment_date ? moment(sub?.last_appointment_date).format("DD/MM/YYYY") : "--";
      return [
        SerialNo,
        transformPatientId,
        transformedEditor,
        transformedPatientStatus,
        transformedLastVisitType,
        transformedEditorName,
        transformePhase,
        transformCycleCount,
        transformedStatus,
        transformedGender,
        transformedProduct,
          lastAppointmentDate,
        transformedId,
        transformedPhaseId,
      ];
  });
};

export const SubjectPage = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.snackBar);
  const {loginId, role_id} = useSelector((state) => state.loginData);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [alignment, setAlignment] = React.useState("Subject");
  const [isInvestigator] = useState(role_id === 2);
  const [assigningPiLoader,setAssignPiLoader] = useState(false);
  const [openAssignForAllModal,setOpenAssignForAllModal] = useState(false);
  const [nrows, setnrows] = useState([]);
  const [ncolumns, setncolumns] = useState([]);
  const [ncolumnAlign, setncolumnAlign] = useState([]);
  const [nTableData, setnTableData] = useState({});
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [phaseList, setPhaseList] = useState([]);
  const [piResponse, setPiResponse] = useState([]);
  const [tableFilters, setTablesFilters] = useState({ searchData: "" });
  const [currentPage, setCurrentpage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [statusL, setStatusL] = useState([2]);
  const [rowDataL, setRowDataL] = useState([]);
  const [trialID, setTrialID] = useState(null);
  const [editorList, setEditorList] = useState([]);
  const [editorId, setEditorId] = useState(null);
  const [pIList,setPIList] =useState([]);
  const [order,setOrder] = useState('asc');
  const [orderBy,setOrderBy] = useState('');
  const [statusList, setStatusList] = useState([]);
  const [selectedPhaseId,setSelectedPhaseId] = useState(null);
  const [statusId, setStatusId] = useState(null);
  const [sumbitPiEditorValues,setSubmitPiEditorValues] = useState({});
  const [initialValues, setInitialValues] = useState(addPiSubjectValues);
  const [reAssignInitial, setReAssignInitial] = useState(isInvestigator?reAssignValues:ReAssignPiEditor)
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openReAssignModal, setOpenReAssignModal] = React.useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [type, setType] = useState("");
  const [rowId, setRowId] = useState(null);
  const [keyButton, setKeyButton] = useState(null);
    const [mediaData, setMediaData] = useState(null)
  const [swapRowData, setSwapRowData] = useState(null)
  const [modelData, setModelData] = useState({
    title: "Patient Request",
    children: [
      {
        type: "input",
        name: "name",
        label: "Name",
        placeholder: "Enter Name",
        disabled: true,
      },
      {
        type: "input",
        name: "gender",
        label: "Gender",
        placeholder: "Enter Gender",
        disabled: true,
      },
      {
        type: "input",
        name: "dob",
        label: "dob",
        placeholder: "Enter dob",
        disabled: true,
      },
    ],
  });
  const [reAssignModal, setReAssignModal] = useState(isInvestigator?
  {
    editTitle: "Re-Assign Editor",
    children: [
      {
        type: "dropdown",
        name: "editor_id",
        label: "Editor",
        placeholder: "Select Editor",
        data: [],
        IsRequired: true,
      },
    ],
  }
  :
  {
    editTitle:"Re-Assign PI/Editor",
    children: [
      {
        type: "dropdown",
        name: "principal_investigator",
        label: "Principal Investigator",
        placeholder: "Select principal investigator",
        data: pIList,
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "editor_id",
        label: "Editor",
        placeholder: "Select Editor",
        data: [],
        IsRequired: true,
      },
    ]
    }

)

    // Function to get Editor Dropdown
    const getEditorDropdown = async (Pi_id, row_id) => {
      try {
        setReAssignInitial({ principal_investigator: Pi_id })
        if (Pi_id) {
          let res = await ProductApi.subadminDropDown(5, Pi_id, row_id||'');
          if(res?.data?.length>0){
            setReAssignModal((prevState) => ({
              ...prevState,
              children: prevState.children.map((child) =>
                child.name === "editor_id" ? { ...child, data: res?.data } : child
              ),
            }));
          }
          else{
            setReAssignModal((prevState) => ({
              ...prevState,
              children: prevState.children.map((child) =>
                child.name === "editor_id" ? { ...child, placeholder: "Select Editor", data: [] } : child
              ),
            }));
          }
         
        } else {
          setReAssignModal((prevState) => ({
            ...prevState,
            children: prevState.children.map((child) =>
              child.name === "editor_id" ? { ...child, placeholder: "Select Editor", data: [] } : child
            ),
          }));
        }
      } catch (error) {
        dispatch(setSnackbar({ value: true, message: error||"Editor Dropdown", color: "error" }));
      }
    };
  


  const handleNavigateToDetails = (row) => {
    if (alignment === "Request") {
      setRowId(row[row.length - 2]);
      const data = piResponse.find((editor) => editor.id === row[row.length - 2]);
      setInitialValues({
          name: data?.first_name + ' ' + data?.last_name,
        gender: data?.gender,
        dob: moment(data?.dob).format("DD-MM-YYYY"),
      });
        setMediaData(data?.media?.[0])
      setKeyButton(data?.status_details?.status);
      setOpenEditModal(true);
    } else if (alignment === "Subject") {
      navigate(`patient-details/${row[row.length - 2]}`, {
        state: [
          { name: "Patient", path: location.pathname },
          {
            name: "Patient Details",
            path: `${location.pathname}/patient-details/${row[row.length - 2]}`,
          },
        ],
      });
    }
  };
  
useEffect(()=>{
  setLoading(true);
   setTimeout(() => {
    getPISubjectListing();
    setIsInitialRender(false);
  }, 200);
},[])

  useEffect(() => {
    if(!isInitialRender){
    if (tableFilters?.searchData[tableFilters?.searchData?.length - 1] != " ") {
      setLoading(true);
      getPISubjectListing();
    }}
  }, [currentPage, tableFilters, trialID, editorId, alignment, statusL]);

  useEffect(() => {
    const { rows, columns, columnAlign, TableData } = subjectData(
      alignment,
      rowDataL
    );
    setnrows(rows);
    setncolumns(columns);
    setncolumnAlign(columnAlign);
    setnTableData(TableData);
    if (alignment === "Request") {
      setStatusL([1, 3]);
    } else if (alignment === "Subject") {
      setStatusL([2]);
    }
    setTrialID(null);
    setEditorId(null);
    setStatusId(null);
  }, [alignment]);

  // Function to get PI for Patient Sub Admin by Value and Phase List ID
  const getPIForSubjectSubAdmin = async (id,phase_id) => {
    try {
      let res =  await SubAdminApi.getEditorForSubjectSubAdmin(
        id,
        phase_id
      );
      setPIList(res?.data);
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));

    }
  };

  useEffect(()=>{
    
    if(pIList)
    setReAssignModal((prevState) => ({
      ...prevState,
      children: prevState.children.map((child) =>
        child.name === "principal_investigator" ? { ...child, data: pIList } : child
      ),
    }))


  },[pIList])

  useEffect(() => {
    getPhase();
    getEditorList();
    getStatus();
  }, []);

  const GetMatchedKey = (key)=>{
    switch (key.trim().toLowerCase()) {
      case 's.no':{
        return 'created_at'
      }    
      case 'patient id':{
        return 'patient_id'
      }    
      case 'patient name':{
        return 'name'
      }    
      case 'patient status':{
        return 'life_status'
      }    
      case 'last recorded visit':{
        return 'last_visit_type'
      }    
      case 'investigator':{
        return 'investigator'
      }    
      case 'editor':{
        return 'editor'
      }    
      case 'editor name':{
        return 'editor'
      }    
      case 'phase':{
        return 'phase_name'
      } 
      case 'appointment completed':{
        return 'appointmentCount'
      } 
      case 'cycle no.':{
        return 'cycle'
      }    
      default:
        return '';
    }
  }

  
  const getPISubjectListing = () =>
    {
    const sortBy = GetMatchedKey(orderBy);
    PISubjectApi.getPISubjectList(
      10,
      currentPage,
      tableFilters?.searchData,
      JSON.stringify(statusL),
      editorId,
      isInvestigator?loginId??"":"",
      trialID,
      !isInvestigator?loginId??"":""
      ,sortBy,
      order
    )
      .then((res) => {
        let rowData = convertPISubjectData(res?.data || [],currentPage, res?.page_details, order);
        setRowDataL(rowData);
        setPiResponse(res?.data);
        const { rows, columns, columnAlign, TableData } = subjectData(
          alignment,
          rowData
        );
        setnrows(rows);
        setncolumns(columns);
        setncolumnAlign(columnAlign);
        setnTableData(TableData);

        setTotalPage(
          Math.ceil(res?.page_details?.no_of_records / res?.page_details?.limit)
        );
        setCurrentpage(res?.page_details?.page);
        setLoading(false);
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setLoading(false);
      });
    }

  const getPhase = () =>
    PISubjectApi.getPhaseDropDown("",isInvestigator?loginId:'',!isInvestigator?loginId:'')
      .then((res) => {
        const data = res?.data?.map((val) => ({
          id: val.id,
            name: `${val.title} (${val?.product_details?.name})`,
        }));
        setPhaseList(data);
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
      });

  const getStatus = () =>
    PISubjectApi.statusDropdown()
      .then((res) => {
        const data = res?.data?.map((val) => ({
          id: val.id,
            name: val.status.charAt(0).toUpperCase() + val.status.slice(1),
        }));
        setStatusList(data);
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
      });

  const getEditorList = () =>
    PISubjectApi.subadminDropDown(isInvestigator?loginId:'',!isInvestigator?loginId:'')
      .then((res) => {
        setEditorList(res?.data);
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
      });

  const handleTableFilters = (e) => {
    setTablesFilters({ ...tableFilters, searchData: e });
    setCurrentpage(1)
  };

  const handlePagination = async (value) => {
    setCurrentpage(value);
  };

  const handleDropDownChange = (e, id) => {
    setCurrentpage(1)
    if (id === "trialList") {
      setTrialID(e.target.value);
    } else if (id === "editorList") {
      setEditorId(e.target.value);
    } else if (id === "StatusList") {
      setStatusId(e.target.value);
      if (e.target.value) {
        setStatusL([e.target.value]);
      } else {
        setStatusL([1, 3]);
      }
    }
  };
  const handleEditModelValues = () =>
  {
    setSubmitLoading(true);
    PISubjectApi.updateStatus(rowId, type == "Accept" ? 2 : 3)
      .then((res) => {
        setOpenEditModal(false);
        setLoading(true);
        getPISubjectListing();
        dispatch(setSnackbar({ value: true, message: res.message, color:"success" }));
        setSubmitLoading(false);
      })
      .catch((error) => {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        setSubmitLoading(false);
      });
  }

  const handleSwapEditorButton = (row) => {
    setRowId(row[row.length - 2])
    setOpenReAssignModal(true)
    const subData = piResponse.find((sub) => sub.id === row[row.length - 2]);
    setSwapRowData(subData)
    setReAssignInitial({
      editor_id: subData?.editorDetails?.id,
    });
    setReAssignModal((prevState) => ({
      ...prevState,
      children: prevState.children.map((child) =>
        child.name === "editor_id" ? { ...child, data: editorList } : child
      ),
    }));
  };

  const handleSwapPIAndEditorButton = (row) => {
    setSelectedPhaseId(row[row.length - 1]);
    getPIForSubjectSubAdmin(2,row[row.length - 1])
    setRowId(row[row.length - 2])
    setOpenReAssignModal(true)
    const subData = piResponse.find((sub) => sub.id === row[row.length - 2]);
    getEditorDropdown(subData?.investigator_id , row[row.length - 1])
    setReAssignInitial({
      principal_investigator: subData?.investigator_id|"",
      editor_id: subData?.editorDetails?.id||"",
    });
  };

  const handleUpdatePiEditor = async (data) => {
    setAssignPiLoader(true);
    try {
      const response = await ProductApi.updatePiEditor(data);
      setOpenReAssignModal(false)
      getPISubjectListing();
      dispatch(
        setSnackbar({
          value: true,
          message: response.message,
          color: "success",
        })
      );
    } catch (error) {
      dispatch(
        setSnackbar({
          value: true,
          message: error || error.message || "Failed to delete phase",
          color: "error",
        })
      );
    }
    finally{
      setSubmitPiEditorValues({});
      setAssignPiLoader(false);
    }

  }

  const SumbitAssignAllPiAndEditor = (action)=>{   
    setOpenAssignForAllModal(false); 
    if(action==="YES"){
      handleUpdatePiEditor({ investigator_id: sumbitPiEditorValues?.principal_investigator, editor_id: sumbitPiEditorValues?.editor_id, phase_id: selectedPhaseId });
    }
    else if(action === "NO"){
      handleUpdatePiEditor({ investigator_id: sumbitPiEditorValues?.principal_investigator, editor_id: sumbitPiEditorValues?.editor_id, subject_id: rowId, phase_id: selectedPhaseId});

    }
  }

  React.useEffect(()=>{
    if(Object.keys(sumbitPiEditorValues).length>0){
      setOpenReAssignModal(false);
      setTimeout(() => {
        setOpenAssignForAllModal(true);
      }, 200);
    }

  },[sumbitPiEditorValues])


  const handleUpdateEditor = async (values) => {
    setSubmitLoading(true);
    try {
      const response = await ProductApi.updatePiEditor({ investigator_id: loginId, editor_id: values?.editor_id, phase_id: swapRowData?.phase_id, subject_id: rowId });
      setOpenReAssignModal(false)
      setLoading(true)
      getPISubjectListing()
      dispatch(setSnackbar({ value: true, message: response.message, color: "success", }));
      setSubmitLoading(false);
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error || error.message || "Failed to delete phase", color: "error", }));
      setSubmitLoading(false);
    }
  }

  const handleOpenAssignPiAndEditorModal = (values)=>{
    const subData = piResponse.find((sub) => sub.id === rowId);
    if ((subData?.editorDetails)?.length > 0 && (subData?.investigatorDetails)?.length > 0) {
      handleUpdatePiEditor({ investigator_id: values?.principal_investigator, editor_id: values?.editor_id, subject_id: rowId, phase_id: selectedPhaseId??"" });
    } else {
      setSubmitPiEditorValues(values)
    }
  }

  const handleSort = (property)=>{
    const isAsc = orderBy === property && order === 'asc'; // On same column click change order
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  useEffect(()=>{
    if(orderBy){
      getPISubjectListing();
    }
  },[orderBy,order])

  return (
    <>
      <div className="flex flex-col gap-4 bg-[#F0F1F5] p-0 !pb-0 pt-3">
        {openEditModal ? (
        <CustomModel
          modalInitialValues={initialValues}
          initialValuesValidationSchema={piSubjectValidationSchema}
          open={openEditModal}
          setOpen={setOpenEditModal}
          setModelData={setModelData}
          modelData={modelData}
          handleModelValues={handleEditModelValues}
          buttonRejectAccept={keyButton}
          setType={setType}
          mediaData={mediaData}
            isLoadingBtn={submitLoading}
        />
        ) : (
          <CustomModel
            rowId={rowId}
            modalInitialValues={ reAssignInitial}
            initialValuesValidationSchema={isInvestigator? reAssignPiValidationSchema: ReAssignValidationSchema}
            open={openReAssignModal}
            setOpen={setOpenReAssignModal}
            setModelData={setReAssignModal}
            modelData={reAssignModal}
            handleModelValues={isInvestigator?handleUpdateEditor:handleOpenAssignPiAndEditorModal}
            isLoadingBtn={submitLoading}
            onChange={getEditorDropdown}
          />
        )}
        <CustomTable
          rows={nrows}
          columnAlign={ncolumnAlign}
          columns={ncolumns}
          TableData={{
            ...nTableData,
            filters: nTableData?.filters?.map((item) => {
              if (item?.id === "trialList") {
                return {
                  ...item,
                  MenuList: phaseList,
                  dropDownValue: trialID,
                };
              } else if (item?.id === "editorList") {
                return {
                  ...item,
                  MenuList: editorList,
                  dropDownValue: editorId,
                };
              } else if (item?.id === "StatusList") {
                return {
                  ...item,
                  MenuList: statusList,
                  dropDownValue: statusId,
                };
              }
              return item;
            }),
          }}
          paddingTop={"pt-0"}
          alignment={alignment}
          setAlignment={setAlignment}
          handleSwapButton={isInvestigator ? handleSwapEditorButton : handleSwapPIAndEditorButton}
          loading={loading}
          handleNavigate={handleNavigateToDetails}
          handleTableFilters={handleTableFilters}
          handleChangePage={handlePagination}
          handleChangeDropDown={handleDropDownChange}
          tableFilters={tableFilters}
          currentPage={currentPage}
          totalpage={totalPage}
          order={order}
          orderBy={orderBy}
          EnableSorting={true}
          handleSort ={handleSort}
        />
      </div>
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={setSnackbar}
        color={snackbar?.color}
        message={snackbar?.message}
      />

   <AddPiAndEditorForAllModalContent
       open={openAssignForAllModal}
       setOpen={setOpenAssignForAllModal}
       handleClick={SumbitAssignAllPiAndEditor}
       isLoading={assigningPiLoader}
       />
    </>
  );
};
