import React, { useCallback, useEffect, useState } from "react";
import {
    DocotorIcon,
    DocotorPairIcon,
} from "../../assets/common-svg/CommonSvg";
import SubjectApi from "../../services/subjectService.js";
import CustomTable from "../../components/custom-table/CustomTable";
import { CustomCard } from "../../components/custom-card/CustomCard";
import { useNavigate, useParams } from "react-router";
import SkeletonLoader from "../../utilites/SkeltonLoader";
import { editorListingDetailData } from "../../utils/tableData";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import SubAdminApi from "../../services/subAdminService";
import { useDispatch, useSelector } from "react-redux";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar.js";
import { setSnackbar } from "../../store/reducers/snackBar"
import moment from "moment";

const convertSubjectData = (data ,curr_page, page_details, order) => {
  return data?.map((sub,index) => {
    let SerialNo = order=='desc'? (page_details?.no_of_records-((curr_page-1)*10))-index : index+1+((curr_page-1)*10);
    let transformedPatientId = sub?.patient_id || "--";
    let transformedName = sub?.first_name + " " + sub?.last_name || "";
    let transformedPatientStatus = sub?.life_status == 'lost' ? 'Lost To Follow Up': sub?.life_status;
    let transformedLastVisitType = sub?.last_visit_type||"--"
    let transformedPhase = sub?.phase_name;
    let transformedCount = sub?.appointmentCount;
    let transformCycleCount = sub?.cycle;
    let transformedGender = sub?.gender;
    let transformedId = sub?.id;
    let lastAppointmentDate = sub?.last_appointment_date ? moment(sub?.last_appointment_date).format("DD/MM/YYYY") : "--";
    return [
      SerialNo,
      transformedPatientId,
      transformedName,
      transformedPatientStatus,
      transformedLastVisitType,
      transformedPhase,
      transformedCount,
      transformCycleCount,
      transformedGender,
      lastAppointmentDate,
      transformedId,
    ];
  });
};

export const EditorListingDetails = () => {
  const dispatch = useDispatch();
  const loginID = useSelector((state) => state.loginData.loginId);
  const snackbar = useSelector((state) => state.snackBar);
  const [loading, setLoading] = useState(false);
  const [loadingDropdown, setLoadingDropdown] = useState(false);
  const [editorDetailedData, setEditorDetailedData] = useState({});
  const [tableFilters, setTablesFilters] = useState({ searchData: "" });
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentpage] = useState(1);
  const [phaseID, setPhaseID] = useState(null);
  const [order,setOrder] = useState('');
  const [orderBy,setOrderBy] = useState('asc');
  const [subjectData, setSubjectData] = useState(editorListingDetailData);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleNavigateToDetails = useCallback(
    (row) => {
      const UpdatedNavigationArray = location.state;
      UpdatedNavigationArray?.push({
        name: "Patient Details",
        path: `${location.pathname}/patient-details/${row[row?.length - 1]}`,
      });
      navigate(`patient-details/${row[row?.length - 1]}`, {
        state: UpdatedNavigationArray,
      });
    },
    [navigate]
  );

  const getEditorDetails = async () => {
    setLoading(true);
    try {
      const res = await SubAdminApi.getSubAdminDetails(id);
      setEditorDetailedData(res?.data || {});
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEditorDetails();
  }, []);

  const GetMatchedKey = (key)=>{
        
    switch (key.trim().toLowerCase()) {
      case 's.no':{
        return 'created_at'
      }    
      case 'patient id':{
        return 'patient_id'
      }    
      case 'patient name':{
        return 'name'
      }    
      case 'patient status':{
        return 'life_status'
      }    
      case 'last recorded visit':{
        return 'last_visit_type'
      }    
      case 'investigator':{
        return 'investigator'
      }    
      case 'editor':{
        return 'editor'
      }    
      case 'phase':{
        return 'phase_name'
      }    
      case 'appointment completed':{
        return 'appointmentCount'
      }    
      case 'cycle no.':{
        return 'cycle'
      }    
      default:{
        return '';
      }
    }
  }


  async function getSubjectList() {
    const sortBy = GetMatchedKey(orderBy);

    try {
      const res = await SubjectApi.getSubjectList(
        phaseID,
        currentPage,
        10,
        tableFilters?.searchData,
        id,
        loginID,
        "",
        "",
        sortBy,
        order,
      );
      let rowData = convertSubjectData(res?.data || [] ,currentPage, res?.page_details, order);
      setSubjectData((prev) => ({
        ...prev,
        rows: [...rowData],
      }));
      setTotalPage(
        Math.ceil(res?.page_details?.no_of_records / res?.page_details?.limit)
      );
      setCurrentpage(res?.page_details?.page);
      setLoadingDropdown(false);
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  }

  const handlePagination = async (value) => {
    setCurrentpage(value);
  };

  const handleTableFilters = (e) => {
    setTablesFilters({ ...tableFilters, searchData: e });
  };

  useEffect(() => {
    if (tableFilters?.searchData[tableFilters?.searchData?.length - 1] != " ") {
      setLoadingDropdown(true);
      getSubjectList();
    }
  }, [currentPage, tableFilters, id, phaseID]);

  const handleDropDownChange = (e) => {
    setPhaseID(e.target.value);
  };

  const handleSort = (property)=>{
    const isAsc = orderBy === property && order === 'asc'; // On same column click change order
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  useEffect(()=>{
   if(orderBy){
    getSubjectList();
   }
  },[orderBy,order])

  return (
    <div className="p-2 pt-4 bg-[#F0F1F5]">
      <div className="mb-4 pl-3">
        <BreadCrumb BreadCrumbsListing={[
          {
               name: 'Assigned Editors',
               path: 'assigned-editors'
          },
          {   name: 'Details',
               path: location.pathname
          }
        ]} />
      </div>

      <div className="pl-3 pr-3">
        {" "}
        {loading ? (
          <div className="col-start-1 col-end-4 ">
            <SkeletonLoader rowCount={1} isTable={false} height={160} />
          </div>
        ) : (
          <>
            {" "}
            <CustomCard
              MainHeading={editorDetailedData?.name}
              SubHeading={`${editorDetailedData?.email || ""}  |  ${
                editorDetailedData?.phone || ""
              }`}
              main_heading_class={"font-bold"}
              NoWrap={true}
              DetailsArray={
                [
                ]
              }
              startIcon={<DocotorIcon Height="50px" Width="52px" />}
              endIcon={<DocotorPairIcon />}
              ArrayLowGapBoolean={true}
              chartData={editorDetailedData?.phaseDetails || []}
              hideEditDelete={true}
            />{" "}
          </>
        )}
      </div>
      <CustomTable
        rows={subjectData?.rows}
        columnAlign={subjectData?.columnAlign}
        columns={subjectData?.columns}
        TableData={subjectData?.TableData}
        handleNavigate={handleNavigateToDetails}
        handleTableFilters={handleTableFilters}
        tableFilters={tableFilters}
        handleChangePage={handlePagination}
        loading={loadingDropdown}
        currentPage={currentPage}
        totalpage={totalPage}
        dropDownValue={phaseID}
        handleChangeDropDown={handleDropDownChange}
        order={order}
        orderBy={orderBy}
        EnableSorting={true}
        handleSort ={handleSort}
      />
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={setSnackbar}
        color={snackbar?.color}
        message={snackbar?.message}
      />
    </div>
  );
};
