import httpService from "./httpService";

const PISubjectApi = {
    
    getPISubjectList: (limit, page, search, status, editor_id, investigator_id='', phase_id,user_id='',sort_by='', order_by='asc') => httpService.get('newSubjectList', {
        params: {
            limit,
            page: page,
            searchData: search,
            status,
            editor_id,
            investigator_id,
            phase_id,
            user_id,
            sort_by,
            order_by
        }
    }),
    getPhaseDropDown: (search, investigator_id='',user_id='') => httpService.get('phaseDropDown', {
        params: {
            searchData: search,
            investigator_id,
            user_id
        }
    }),
    subadminDropDown: (investigator_id='',user_id='') => httpService.post(`subadminDropDown/${5}`, {
        "ids": [],
        investigator_id,
        user_id
    }),
    statusDropdown: () => httpService.get('subjectStatusDropDown'),
    updateStatus: (id, status) => httpService.post(`updateSubjectStatus/${id}`, {
        status
    })
}

export default PISubjectApi;