import { Chip, Typography } from "@mui/material"

export const CustomChipCard = ({ activeTab, tab = 0, setActiveTab, data, setCurrentpage,resetSortFilter=()=>{} }) => {
    return <div onClick={() => {resetSortFilter();setCurrentpage(1);setActiveTab(data?.id || tab);}}   className={`cursor-pointer p-3 flex flex-col gap-2 mb-2 bg-[#FFFFFF] ${activeTab == data?.id ? " border !border-sky-500" : "border border-white"}  rounded-lg`
    }>
        <Typography variant="body1" fontWeight={"bold"}>{data?.title}</Typography>
        <div className='flex-wrap flex gap-3'>
            <Chip size="small" color="success" label={data?.badglesValue?.typeBadglesValue}/>
            <Chip size="small" color={data?.badglesValue?.statusBadglesValue ===
                "Pending to Start" ? "error" : data?.badglesValue?.statusBadglesValue === 'Enrollment Pending' ? "warning" : "success"} label={data?.badglesValue?.statusBadglesValue} />
        </div>
    </div>
}
