import { useState, useEffect, useRef } from 'react'
import { LoadingButton, Skeleton } from "@mui/lab"
import { CheckGreen, InfoIcon, QuestionInfo } from "../../../assets/common-svg/CommonSvg"
import CustomAccordion from "../../../components/custom-accordion/CustomAccordion"
import CustomDropdown from "../../../components/custom-dropdown/CustomDropdown"
import FormPreview from "../../../components/form-template/FormPreview"
import { CustomHeading } from "../../../components/custom-heading/CustomHeading"
import { useDispatch, useSelector } from "react-redux"
import QueryApi from "../../../services/queryManage"
import { setSnackbar } from "../../../store/reducers/snackBar"
import { Typography } from '@mui/material'
import SubjectApi from '../../../services/subjectService'
import DeleteConfirmContent from '../../../components/modal-contents/DeleteConfirmModalContent'
import { getUserHasAccess } from '../../../utils/authorities'
import { AccessDenied } from '../../access-denied/AccessDenied'
import ConfirmationModal from '../../../components/modal-contents/ConfirmationModal'
import {SubmitAppointmentConfirmMessage, SubmitAppointmentCompleteQueries } from '../../../utils/common'

export const FormView = ({
    acc,
    getQueryHistory,
    handleExpansion,
    queryLoading,
    index,
    SubjectData,
    patientLifeValue
}) => {
    const dispatch = useDispatch()
    const submitButtonRef = useRef(null)
    const LoginData = useSelector((state) => state.loginData);
    const role_id = LoginData?.role_id;
    const userAuthority = LoginData?.auth || [];
    const [accordionLoading, setAccordionLoading] = useState({ accordion: 0, form: 0 });
    const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(null)
    const [openSubmitConfirmation ,setOpenSubmitConfirmation] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [versionData, setVersionData] = useState([]);
    const [appointmentData, setAppointmentData] = useState({ version: null, type: "", delete_media_id: [], media: [], isDisableQuery: true, readOnly: false, checkDisableQuery: true });
    const [buttonLoading, setButtonLoading] = useState({ queryButton: false, submitButton: false, saveAsDraft: false, approveButton: false })
    const [descriptionErrorFlag,setDescriptionErrorFlag] = useState(false);
    const handleChangeDropDown = (e) => {
        setAppointmentData({ ...appointmentData, version: e.target.value })
    }

    const validateChildItems = (data = []) => {
        
        if(data.length > 0) {

            const validIds = new Set(data.map(item => item.id)); // Create a set of valid IDs
    
            return data.map(item => {
                // Create a shallow copy of the item
                const newItem = { ...item };
                
                if (newItem.childItems) {
                    // Filter childItems to only include valid IDs
                    newItem.childItems = newItem.childItems.filter(childId => validIds.has(childId));
                }
        
                return newItem; // Return the modified item
            });
        }
        
    }    

    const getAppoinmentVersion = async (id) => {
        setAccordionLoading({ ...accordionLoading, accordion: id })
        try {
            const res = await QueryApi.getApointmentVersion(id);
            let menuData = res?.data.map(op => {
                return {
                    id: op.id, name: op.version
                }
            })
            setAppointmentData({ ...appointmentData, version: menuData[menuData.length - 1].id })
            setVersionData(menuData)
        } catch (error) {
            dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        } finally {
            setAccordionLoading({ ...accordionLoading, accordion: 0 })
        }
    }
    const getAppoinmentDetails = async (id) => {
        setAccordionLoading({ ...accordionLoading, form: id })
        try {
            let res = await QueryApi.getAppointmentAnswerDetail(appointmentData.version, SubjectData.id, id);
            res = res?.data          
            let data = {
                id: res?.id,
                questions: res?.appointment_question?.questions ? JSON.parse(res.appointment_question.questions) : [],
                answers: res?.appointment_answer?.answer_packet ? JSON.parse(res.appointment_answer.answer_packet) : [],
                checkAnswer: res?.appointment_answer?.answer_packet ? JSON.parse(res.appointment_answer.answer_packet) : [],
                description: res?.appointment_answer?.description ?? null,
                totalQueryCount: res?.appointment_answer?.totalQueryCount,
                media: res?.appointment_answer?.media ?? [],
                answer_id: res?.appointment_answer?.id,
                template_queries: res?.appointment_answer?.appointment_template_queries ?? [],
                status: [],
                is_all_queries_resolved: res?.appointment_answer?.is_all_queries_resolved,
                is_submitted: res?.appointment_answer?.is_submitted
            };
            if (res.appointment_answer) {
                let approved_by = typeof res?.appointment_answer?.approved_by === 'string'
                    ? res.appointment_answer.approved_by.split(",")
                    : [];

                data = {
                    ...data,
                    approved_PI_id: res?.appointment_answer?.approved_PI_id,
                    approved_QA_id: res?.appointment_answer?.approved_QA_id,
                    approved_PM_id: res?.appointment_answer?.approved_PM_id,
                    checkUsers: [
                        ...approved_by,
                        res?.appointment_answer?.approved_PI_id,
                        parseInt(res?.appointment_answer?.approved_QA_id),
                        res?.appointment_answer?.approved_PM_id
                    ].filter(Boolean) // This filters out any undefined or null values
                };
            }
            if (res?.appointment_answer && res?.appointment_answer?.type) {
                data = {
                    ...data,
                    type: res?.appointment_answer?.type,
                };
            }
            if (res?.appointment_answer && res?.appointment_answer?.totalQueryCount == 0 && res?.appointment_answer?.appointment_answer_status == "completed") {
                data = {
                    ...data,
                    status: [
                        ...data.status,
                        {
                            icon: <CheckGreen Filled="#00B278" />,
                            text: "Completed",
                            color: "#00B278"
                        }
                    ],
                    color: "#00B278"
                };
            } else if (res?.appointment_answer?.appointment_answer_status == "in-progress" || res?.appointment_answer == null) {
                data = {
                    ...data,
                    status: [
                        ...data.status,
                        {

                            icon: <InfoIcon Height={20} Width={20} Filled="#FB9D2C" />,
                            text: "In Progress",
                            color: "#FB9D2C"

                        }
                    ],
                    color: "#FB9D2C"
                };
            }
            if (res?.appointment_answer && res?.appointment_answer?.totalQueryCount > 0) {
                data = {
                    ...data,
                    status: [
                        ...data.status,
                        {
                            icon: (
                                <div className="border-l-2">
                                    <Typography variant="body1" className="ml-2 rounded-3xl text-center align-self-center text-[0.8rem] w-[1.2rem] h-[1.2rem] text-white bg-[#FF0000]">
                                        {res?.appointment_answer?.totalQueryCount}
                                    </Typography>
                                </div>
                            ),
                            text: "Query Raised",
                            color: "#FF0000"
                        }
                    ],
                    color: "#FB9D2C"
                };
            }
            let read_only = res?.appointment_answer?.appointment_answer_status == "completed" ? true : false
            let disbaleQuery = !versionData.find(op => op.id == appointmentData?.version + 1)?.id ? false : true
            disbaleQuery = role_id == 5 ? true : disbaleQuery
            setAppointmentData({ ...appointmentData, ...data, delete_media_id: [], isDisableQuery: disbaleQuery, readOnly: read_only, checkDisableQuery: disbaleQuery })
        } catch (error) {
            console.log(error, "errorerror");
            dispatch(setSnackbar({ value: true, message: error, color: "error" }));
        } finally {
            setAccordionLoading({ ...accordionLoading, form: 0 })
        }
    }
    const handleSubmit = (check) => {
        if (check == "draft") {
            setButtonLoading((prev) => ({ ...prev, saveAsDraft: true }))
        } else {
            setButtonLoading((prev) => ({ ...prev, submitButton: true }))
        }
        setAccordionLoading({ ...accordionLoading, form: acc?.id })
        const formData = new FormData();
        if (check == "draft") {
            formData.append("type", "draft");
        } else {
            formData.append("type", "submit");
        }
        formData.append("subject_id", SubjectData?.id);
        formData.append("appointment_id", acc.appointment_id);
        formData.append("answer_packet", JSON.stringify(appointmentData?.answers));
        formData.append("version", appointmentData?.version);
        formData.append("status", 1);
        formData.append("is_all_queries_resolved", false);
        for (let i = 0; i < appointmentData?.media?.length; i++) {
            if (!appointmentData.media[i].id)
                formData.append("files", appointmentData.media[i]);
        }
        if (appointmentData.answer_id) {
            formData.append("answer_id", appointmentData.answer_id);
        }
        if (appointmentData.description) {
            formData.append("description", appointmentData.description);
        }
        if (appointmentData?.delete_media_id.length != 0) {
            for (let i = 0; i < appointmentData.delete_media_id.length; i++) {
                formData.append("delete_media_id[]", appointmentData.delete_media_id[i]);
            }
        }

        SubjectApi.setAppointmentAnswerData(formData).then(res => {
            setButtonLoading((prev) => ({ ...prev, submitButton: false, saveAsDraft: false }));
            dispatch(setSnackbar({ value: true, message: res?.message, color: "success" }));
            getAppoinmentDetails(acc.appointment_id);
        }).catch(err => {
            if(err?.response?.data?.status==413){
                setAppointmentData((prev)=>({...prev,media:[]}));
            }
            setButtonLoading((prev) => ({ ...prev, submitButton: false, saveAsDraft: false }));
            dispatch(setSnackbar({ value: true, message: err?.response?.data?.message || err?.message || err, color: "error" }));
            setAccordionLoading({ ...accordionLoading, form: 0 })
        })
    }

    

    const handleChangeForm = (data, name) => {
        if(name.toLowerCase()=='description'){
            const count = data.length;
            if(count>3000){
                setDescriptionErrorFlag(true);
            }
            else{
                setDescriptionErrorFlag(false);
            }
        }
        if (name == "files") {
            setAppointmentData({ ...appointmentData, media: [...appointmentData.media, data] })
        } else {
            setAppointmentData({ ...appointmentData, [name]: data })
        }
    }
    const handleRemoveFiles = () => {
        setDeleteLoading(true)
        let s1 = { ...appointmentData }
        if (s1.media[openDeleteConfirmModal]?.id) {
            s1.delete_media_id.push(s1.media[openDeleteConfirmModal]?.id)
        }
        s1.media.splice(openDeleteConfirmModal, 1)
        setAppointmentData(s1)
        setOpenDeleteConfirmModal(null)
        setDeleteLoading(false)
    }

    useEffect(() => {
        if (acc?.expanded == true) {
            getAppoinmentDetails(acc?.appointment_id)
        }
    }, [appointmentData?.version]);

    useEffect(() => {
        if (acc?.expanded == true) {
            getAppoinmentVersion(acc?.appointment_id)
        } else {
            setAccordionLoading({ version: appointmentData?.version })
        }
    }, [acc?.expanded]);

    const handleApprove = () => {
        setButtonLoading((prev) => ({ ...prev, approveButton: true }));
        setAccordionLoading({ ...accordionLoading, form: acc?.id })
        let data = {
            appointment_id: acc?.appointment_id,
            version: appointmentData?.version
        }
        QueryApi.approveAppointment(data).then(res => {
            setAccordionLoading({ ...accordionLoading, form: 0 })
            getAppoinmentDetails(acc?.appointment_id)
            dispatch(setSnackbar({ value: true, message: res?.message, color: "success" }));
            setButtonLoading((prev) => ({ ...prev, approveButton: false }));
        }).catch(err => {
            setAccordionLoading({ ...accordionLoading, form: 0 })
            dispatch(setSnackbar({ value: true, message: err, color: "error" }));
            setButtonLoading((prev) => ({ ...prev, approveButton: false }));
        })
    }


    const LoadingApproveButton = () => (
        accordionLoading?.form == acc.appointment_id
            ? <Skeleton width={"100%"} height={50} />
            : <LoadingButton
                loading={buttonLoading?.approveButton}
                color={"info"}
                size="large"
                onClick={() => handleApprove()}
            >
                Approve
            </LoadingButton>
    )

   const handleConfirmationSubmit = (confirmation)=>{
    if(confirmation)
    submitButtonRef.current?.click()
      
    setOpenSubmitConfirmation(false);
   }

   const handleOpenSubmitConfirmation = ()=>{
    setOpenSubmitConfirmation(true);
   }
    return (<>
        {accordionLoading?.accordion == acc.appointment_id
            ? <Skeleton width={"100%"} height={100} />
            : <CustomAccordion
                index={index}
                rootDivClass={`form-accordion-${index}`}
                accordionClassName={"!rounded-lg"}
                leftBorder={acc?.leftBorder}
                expanded={acc?.expanded}
                type={acc?.type ?? appointmentData?.type}
                handleExpansion={handleExpansion}
                color={appointmentData?.status?.[0]?.color ?? acc?.color}
                backgroundColor={acc?.backgroundColor}
                title={acc?.title}
                status={appointmentData.status ?? acc?.status}
                style={{
                    marginBottom: "1rem",
                    borderRadius: "12px !important",
                }}
            >
                {getUserHasAccess(userAuthority, "appointmentManager", 121) ? <div>
                    <CustomHeading
                        leftHeading={"Version"}
                        rightHeading={"Query History"}
                        id={acc.appointment_id}
                        queryLoading={queryLoading}
                        handleRightChild={(id) => getQueryHistory(id, appointmentData?.version)}
                        leftChild={<CustomDropdown
                            value={appointmentData?.version}
                            MenuList={versionData || []}
                            MinWidth="6rem"
                            BgColor={"#F0F1F5"}
                            handleChange={handleChangeDropDown}
                            className={" px-2.5 bg-gray-200 rounded-md justify-between items-center inline-flex"}
                        />}
                        rightAuth={{ module: "queryManager", auth: 129 }}
                        rightChild={<QuestionInfo Filled="#3571FF" />}
                    />
                    {accordionLoading?.form == acc.appointment_id ? <div>
                        <div className='flex gap-3 justify-contant-between'>
                            <Skeleton width={"100%"} height={50} />
                            <Skeleton width={"100%"} height={50} />
                        </div>
                        <Skeleton width={"100%"} height={50} />
                        <div className='flex gap-3 justify-contant-between'>
                            <Skeleton width={"100%"} height={50} />
                            <Skeleton width={"100%"} height={50} />
                            <Skeleton width={"100%"} height={50} />
                        </div>
                    </div>
                        : <FormPreview
                            submitButtonRef={submitButtonRef}
                            handleSubmit={handleSubmit}
                            handleRemoveFiles={(index) => setOpenDeleteConfirmModal(index)}
                            setAppointmentData={setAppointmentData}
                            getAppoinmentVersion={getAppoinmentVersion}
                            getAppoinmentDetails={getAppoinmentDetails}
                            formData={validateChildItems(appointmentData?.questions) || []}
                            // formData={appointmentData?.questions || []}
                            SubjectData={SubjectData}
                            answerData={appointmentData?.answers || []}
                            handleChangeForm={handleChangeForm}
                            index={index}
                            appointmentData={appointmentData}
                            handleChangeDescription={handleChangeForm}
                            description={appointmentData?.description}
                            descriptionErrorFlag={descriptionErrorFlag}
                            patientLifeValue={patientLifeValue}
                        />

                    }
                    <div className="flex gap-4 pt-4">
                        {getUserHasAccess(userAuthority, "appointmentManager", 123) && appointmentData?.status && appointmentData?.status[0]?.text != "Completed"
                                && appointmentData?.type != "submit" && patientLifeValue === "alive" && (!appointmentData?.is_submitted || !appointmentData?.is_all_queries_resolved || appointmentData?.type?.toLowerCase() === "draft") && (accordionLoading?.form == acc.appointment_id
                                    ? <Skeleton width={"100%"} height={50} />
                                    : <LoadingButton
                                        loading={buttonLoading?.saveAsDraft}
                                        disabled={descriptionErrorFlag}
                                        color="info"
                                        size="large"
                                        onClick={() => handleSubmit("draft")}
                                    >
                                        Save as draft
                                    </LoadingButton>)}
                        {getUserHasAccess(userAuthority, "appointmentManager", 123) && appointmentData?.status && appointmentData?.status[0]?.text != "Completed"
                            && patientLifeValue === "alive" && (!appointmentData?.is_submitted || !appointmentData?.is_all_queries_resolved || appointmentData?.type?.toLowerCase() === "draft") && (accordionLoading?.form == acc.appointment_id
                                ? <Skeleton width={"100%"} height={50} />
                                : <LoadingButton
                                    loading={buttonLoading?.submitButton}
                                    disabled={descriptionErrorFlag}
                                    color="info"
                                    size="large"
                                    onClick={() => handleOpenSubmitConfirmation()}
                                >
                                    Submit
                                </LoadingButton>)}
                        {getUserHasAccess(userAuthority, "appointmentManager", 124)
                            && appointmentData?.type == "submit"
                            && appointmentData?.answers?.length != 0
                            && appointmentData?.totalQueryCount == 0
                            && !appointmentData.checkUsers.map(op => parseInt(op))?.includes(LoginData?.loginId) && patientLifeValue === "alive"
                            && (role_id == 2 && !appointmentData?.approved_PI_id && !appointmentData?.approved_PM_id && !appointmentData?.approved_QA_id
                                ? <LoadingApproveButton />
                                : role_id == 3 && appointmentData?.approved_PI_id && !appointmentData?.approved_PM_id && !appointmentData?.approved_QA_id
                                    ? <LoadingApproveButton />
                                    : role_id == 4 && appointmentData?.approved_PI_id && !appointmentData?.approved_PM_id && appointmentData?.approved_QA_id
                                        ? <LoadingApproveButton />
                                        : ![2, 3, 4]?.includes(role_id) && appointmentData?.approved_PI_id && appointmentData?.approved_PM_id && appointmentData?.approved_QA_id
                                            ? <LoadingApproveButton />
                                            : "")}
                        
                    </div>
                </div> : <div   >
                    <AccessDenied removeBack={true} />
                </div>}
            </CustomAccordion>}
            <ConfirmationModal
             actionButtons={appointmentData?.answer_id ? appointmentData?.is_all_queries_resolved : true}
             open={openSubmitConfirmation}
             setOpen={setOpenSubmitConfirmation}
             handleConfirmation={handleConfirmationSubmit}
             errMessage={
                appointmentData?.answer_id 
                ? (appointmentData.is_all_queries_resolved 
                    ? SubmitAppointmentConfirmMessage 
                    : SubmitAppointmentCompleteQueries)
                : SubmitAppointmentConfirmMessage
            }
            />


            <DeleteConfirmContent
            open={openDeleteConfirmModal != null}
            setOpen={() => setOpenDeleteConfirmModal(null)}
            handleDelete={handleRemoveFiles}
            isLoading={deleteLoading}
            />
        </>)
}