import httpService from "./httpService";

const SubAdminApi = {
  getSubAdminData: (limit, page, search, role_id) => {
    return httpService.get("subAdminList", {
      params: {
        limit,
        page: page,
        searchData: search,
        role_id,
      },
    });
  },
  getSubAdminListByRoles: () => httpService.get(`subAdminListByRoles`),
  addSubAdmin: (data) => httpService.post(`addSubAdmin`, data),
  editSubAdmin: (data, id) => httpService.post(`updateSubAdmin/${id}`, data),
  deleteSubAdmin: (id) => httpService.delete(`deleteSubAdmin/${id}`),
  getSubAdminDropdown: (id, formData, searchData = "") =>
    httpService.post(
      `subadminDropDown/${id}?searchData=${searchData}`,
      formData || {}
    ),
  getEditorForSubjectSubAdmin: (val, id) =>
    httpService.get(`assignedSubadminDropDown/${val}?phase_id=${id}`),
  formTemplateOrderwise: (data) => httpService.post(`formTemplateOrderwise`, data),
  getSubAdminDetails: (id) => httpService.get(`subAdminDetails/${id}`),
};

export default SubAdminApi;
