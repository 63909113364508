import moment from "moment";
import { RightArrowIcon } from "../../assets/images/drawerIcons/RightArrow";

export const FormValues = ({
  rightValue,
  rightLable,
  leftLable,
  leftValue,
}) => {
  const checkValueAndReturn = (value) => {
    // Additional check to ensure value is not a non-date string like ">3"
    const isValidDate = (date) => {
      return moment(date, "YYYY/MM/DD", true).isValid(); // strict parsing
    };
  
    // Check if value is a valid date
    if (isValidDate(value)) {
      return moment(value, "YYYY/MM/DD").format("DD-MM-YYYY");
    } else {
      return value; // Not a valid date, return original value
    }
  };

  return (
    <div className="flex justify-around p-3 text-center">
      <div className="w-100">
        <div className="text-zinc-500 text-sm font-normal font-['Public Sans'] pb-1">
          {leftLable}
        </div>
        <div className="px-2 py-1 bg-orange-400 rounded justify-center items-start gap-2.5 inline-flex">
          <div className="text-white text-sm font-normal font-['Public Sans'] break-all">
            {checkValueAndReturn(leftValue)}
          </div>
        </div>
      </div>
      <div className="self-center text-center">
        <RightArrowIcon width={150} height={12} />
      </div>
      <div className="w-100">
        <div className="text-zinc-500 text-sm font-normal font-['Public Sans'] pb-1">
          {rightLable}
        </div>
        <div className="px-2 py-1 bg-emerald-500 rounded justify-center items-start gap-2.5 inline-flex">
          <div className="text-white text-sm font-normal font-['Public Sans'] break-all">
            {checkValueAndReturn(rightValue)}
          </div>
        </div>
      </div>
    </div>
  );
};
