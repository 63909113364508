export const navMenuItems = [
    {
        name: "Dashboard",
        icon: "dashboard",
        link: "/",
        allowedDept: [1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13],
    },
    {
        name: "Trials",
        icon: "partnerDecision",
        link: "/partnerMeetings",
        allowedDept: [2],
        authorities: [140],
    },
];
export const PHONE_REGEX = /^(\+91[-\s]?)?[6-9]\d{9}$/;
export const NAME_REGEX = /^(?!\d+$)(?!\s+$)[a-zA-Z0-9\s.]+$/;
export const Hospital_REGEX = /^(?!\d+$)(?!\s+$)[a-zA-Z0-9\s.,]+$/;

export const StatusChangeHeading = "Are you sure you want to change Patient status ?";

export const SubmitAppointmentConfirmMessage = "Are you sure you want to submit?";
export const SubmitAppointmentCompleteQueries = "Complete all the queries and resolve for submit.";

export const TemplateStatus = [{ id: 1, name: "All" }, { id: 2, name: "Completed" }, { id: 3, name: "Saved as Draft" }]

export const GenderList = [{ id: 1, name: "Male" },{ id: 2, name: "Female" },{ id: 3, name: "Others" }] 

export const formTemplateDropdownList = [
    {
      id: "1",
      name: "Screening and Enrollment"
    },
    {
      id: "2",
      name: "Apheresis"
    },
    {
      id: "3",
      name: "Bridging Therapy"
    },
    {
      id: "4",
      name: "Conditioning Chemotherapy"
    },
    {
      id: "5",
      name: "Infusion"
    },
    {
      id: "6",
      name: "Post Infusion Follow Up"
    },
    {
      id: "7",
      name: "Short Term Follow Up"
    },
    {
      id: "8",
      name: "Long Term Follow Up"
    }
  ];

  export const sortTableByOrder = (data, columnIndex, order = 'asc')=> {
    // Validate columnIndex
    if (data?.length === 0 || columnIndex < 0 || columnIndex >= data[0]?.length) {
      return [];
    }

    const sortedData = [...data];


    return sortedData?.sort((a, b) => {
        const valueA = a[columnIndex];
        const valueB = b[columnIndex];

        // Determine the sort order
        const direction = order === 'asc' ? 1 : -1;

        if (typeof valueA === 'number' && typeof valueB === 'number') {
            return (valueA - valueB) * direction;
        } else {
            // Compare as strings, converting to string if necessary
            const strA = String(valueA).toLowerCase();
            const strB = String(valueB).toLowerCase();
            if (strA < strB) return -1 * direction;
            if (strA > strB) return 1 * direction;
            return 0; // They are equal
        }
    });
}
