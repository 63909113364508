import React, { useState, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DocumentIcon, MedicineIcon } from "../../assets/common-svg/CommonSvg"
import { TableCard } from "../../components/table-card/table-card"
import CustomSearchbar from "../../components/custom-searchbar/CustomSearchbar"
import CustomDropdown from "../../components/custom-dropdown/CustomDropdown"
import { RejectTrialManagementContent } from "../../components/modal-contents/RejectTrialManagementModalContent"
import SkeletonLoader from "../../utilites/SkeltonLoader"
import { resendTrialManagementValues } from "../../utils/formik/initialValues"
import CustomModel from "../../components/custom-model/CustomModel"
import TrailsManagementApi from "../../services/trailsManagement"
import { setSnackbar } from "../../store/reducers/snackBar"
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar"
import { Typography } from "@mui/material"
import { SpinnerLoader } from "../../assets/loader/loader"
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from "moment/moment"
import CustomDrawer from "../../components/custom-drawer/CustomDrawer"
import HeaderBackNav from "../../components/header-back-navigation/HeaderBackNav"
import { DoctorIcon } from "../../assets/products-svg/ProductSvg"
import { useLocation, useNavigate } from "react-router"
import NoDataCard from "../../components/no-data-card"
import * as Yup from "yup";

const statusFilter = [{ id: 1, name: "Pending", value: "pending" }, { id: 2, name: "Accepted", value: "accepted" }, { id: 3, name: "Rejected", value: "rejected" }]
let trailsFilter = {
    page: 1,
    limit: 10,
    maxPages: null,
};
export const TrailManagement = () => {
    const snackbar = useSelector((state) => state.snackBar);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [openResendModal, setOpenResendModal] = React.useState(false);
    const [openRejectModal, setOpenRejectModal] = React.useState({ open: false, title: "" });
    const [rejectDetails, setRejectDetails] = useState([])
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [trailMangeData, setTrailMangeData] = useState([])
    const [resendData, setResendData] = useState({ status: "", id: "", title: "" })
    const [dataFilter, setDataFilter] = useState({ search: "", status: "", formSearch: "", piSearch: "" })
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [drawerData, setDrawerData] = useState({ title: "", data: [] })
    const [modelData, setModelData] = useState({
        title: "Resend Request",
        children: []
    })

    const getTrailsData = () => {
        setSpinner(true)
        trailsFilter.page === 1 && setLoading(true);
        TrailsManagementApi.getAllRequestedTrials(
            trailsFilter.limit,
            trailsFilter.page,
            { ...dataFilter, status: statusFilter?.find(op => op.id == dataFilter?.status)?.value }
        ).then(res => {
            if (res?.status == 200) {
                let data = res?.data.map(data => {
                    let cardIcon = {
                        requestID: data?.id,
                        id: data?.phase_request?.[0]?.id,
                        status: data?.phase_request?.[0]?.status,
                        title: `${data?.product_details?.name}: ${data?.title}`,
                        code: data?.phase_code,
                        icon: <MedicineIcon /> // Assuming this is a placeholder, adjust as necessary
                    };

                    let cardLable = [
                        { fieldLable: "Phase Started", fieldValue: moment(data?.phase_start_date).format("DD/MM/yyyy"), textColor: "text-black", className: "", click: "none" },
                        { fieldLable: "Medical Expert", fieldValue: data.phase_invest[0]?.name.toString(), textColor: "text-black", className: "", click: "none" },
                        { fieldLable: "Total PI", fieldValue: data?.totalPi?.toString(), textColor: "#3571FF", className: "cursor-pointer underline ", click: "pi" },
                        { fieldLable: "Assigned Forms", fieldValue: data?.assignedForms?.toString(), textColor: "#3571FF", className: "cursor-pointer underline ", click: "form" },
                        { fieldLable: "Status", fieldValue: data?.phase_request?.[0]?.status?.charAt(0).toUpperCase() + data?.phase_request?.[0]?.status?.slice(1), textColor: getStatusColor(data?.phase_request?.[0]?.status), className: "", click: "none" }
                    ];

                    return { cardIcon, cardLable };
                });
                trailsFilter.maxPages = Math.ceil(
                    res.page_details.no_of_records / trailsFilter.limit
                );
                res.page_details.page > 1
                    ? setTrailMangeData((prev) => [...prev, ...data])
                    : setTrailMangeData(data);
                setSpinner(false)
            } else if (res?.status == 204) {
                setTrailMangeData([]);
                setSpinner(false)
            }
            setLoading(false);
        }).catch(error => {
            dispatch(setSnackbar({ value: true, message: error, color: "error" }));
            setSpinner(false)
            setLoading(false);
        });
    };
    const PageIncrement = () => {
        trailsFilter.page++;
    };
    const ResetFilter = () => {
        trailsFilter.page = 1;
    };

    useEffect(() => {
        ResetFilter();
        getTrailsData();
        PageIncrement()
    }, [dataFilter?.search, dataFilter?.status]);

    const toggleDrawer = (type, id) => {
        if (type == "pi") {
            setDrawerData({ ...drawerData, data: [], id: id, loading: true })
            getPiData(id)
            setDrawerOpen(!drawerOpen)
        } else if (type == "form") {
            setDrawerData({ ...drawerData, data: [], id: id, loading: true })
            getFormData(id)
            setDrawerOpen(!drawerOpen)
        }
    }
    const handleBackDrawer = () => {
        setDataFilter({ ...dataFilter, formSearch: "", piSearch: "" })
        setDrawerData({ data: [], title: "" })
        setDrawerOpen(!drawerOpen)
    }
    useEffect(() => {
        if (drawerData?.type == "form") {
            setDrawerData({ ...drawerData, data: [], loading: true })
            getFormData(drawerData?.id)
        } else if (drawerData?.type == "pi") {
            setDrawerData({ ...drawerData, data: [], loading: true })
            getPiData(drawerData?.id)
        }
    }, [dataFilter?.formSearch, dataFilter?.piSearch]);

    const handleButton = (id, status, title, name) => {
        if (title) {
            let model = { ...modelData }
            model.title = title
            if(name === "acceptBtn"){
                model.children = [{ type: "checkbox", name: "terms", label: "Terms & Condition",  value: true, placeholder: "I have reviewed all the forms", IsRequired: true }]     
            } else {
                model.children = [{ type: "description", name: "description", label: "Description", placeholder: "Description", IsRequired: true }]
            }
            setModelData(model)
            setResendData({ status: status, id: id })
            setOpenResendModal(true);
        } else {
            setResendData({ status: status, id: id })
        }
    }
    const handleOpenRejectModal = (id, title) => {
        TrailsManagementApi?.getTrialHistory(id).then(res => {
            setRejectDetails(res?.data)
            setOpenRejectModal({ open: true, title: title });
        }).catch(err => {
            dispatch(setSnackbar({ value: true, message: err, color: "error" }));
        })
    }


    function getStatusColor(status) {
        switch (status.toLowerCase()) {
            case "pending":
                return "#FB9D2C";
            case "accepted":
                return "#00B278";
            case "rejected":
                return "#FF2023";
            default:
                return "text-black";
        }
    }


    const handleModelValues = (values) => {
        setSubmitLoading(true);
        let data = { phase_request_id: resendData?.id, comment: values?.description || "I have reviewed all the forms", status: resendData?.status }
        TrailsManagementApi?.updateRequest(data).then(res => {
            dispatch(setSnackbar({ value: true, message: res?.message, color: "success" }));
            ResetFilter();
            setTimeout(() => {
                getTrailsData();
                PageIncrement();
                setOpenResendModal(false);
                setSubmitLoading(false);
            }, 500);
        }).catch(err => {
            dispatch(setSnackbar({ value: true, message: err, color: "error" }));
            setSubmitLoading(false);
        })
    }

    const fetchData = () => {
        if (trailsFilter?.page <= trailsFilter?.maxPages) {
            getTrailsData();
            PageIncrement();
        }
    }

    const getPiData = (id) => {
        TrailsManagementApi.getAllPiData(dataFilter, id).then(res => {
            setTimeout(() => {
                setDrawerData({ title: "Assigned PI", type: "pi", id: id, data: res?.data?.data, loading: false })
            }, 500);
        }).catch(err => {
            dispatch(setSnackbar({ value: true, message: err, color: "error" }));
            setDrawerData({ ...drawerData, loading: false })

        })
    }
    const getFormData = (id) => {
        TrailsManagementApi.getAllFormData(dataFilter, id).then(res => {
            setTimeout(() => {
                setDrawerData({ title: "Assigned Forms", id: id, type: "form", data: res?.data?.data, loading: false })
            }, 500);
        }).catch(err => {
            dispatch(setSnackbar({ value: true, message: err, color: "error" }));
            setDrawerData({ ...drawerData, loading: false })
        })
    }  

    // Determine if 'terms' should be required
    const isTermsRequired = useMemo(() => modelData?.children?.some(field => field.type === 'checkbox'), [modelData]);

    // Define the validation schema based on the computed value
    const resendTrialManagementValidationSchema = useMemo(() => Yup.object().shape({
        description: Yup.string().when([], {
        is: () => !isTermsRequired,
        then: Yup.string().max(255).required('Description is required'),
        otherwise: Yup.string().max(255),
        }),
        terms: Yup.boolean().when([], {
        is: () => isTermsRequired,
        then: Yup.boolean().oneOf([true], 'You must review all the forms').required('Review all the forms'),
        otherwise: Yup.boolean(),
        }),
    }), [isTermsRequired]);
    
    return (
        <>
            <div className="p-3 flex flex-col gap-4">
                <CustomModel
                    modalInitialValues={resendTrialManagementValues}
                    initialValuesValidationSchema={
                        resendTrialManagementValidationSchema
                    }
                    open={openResendModal}
                    setOpen={setOpenResendModal}
                    setModelData={setModelData}
                    modelData={modelData}
                    handleModelValues={handleModelValues}
                    isLoadingBtn={submitLoading}
                />
                <div className="flex justify-center items-center flex-col w-full lg:grid lg:grid-cols-7 gap-4 p-1">
                    <div className={"col-start-1 w-full col-end-3"}>
                        <CustomSearchbar
                            value={dataFilter?.search}
                            handleSearch={(value) =>
                                setDataFilter({ ...dataFilter, search: value })
                            }
                            BgColor={"#FFFFFF"}
                            Width={"370px"}
                            Placeholder={"Search by Name"}
                        />
                    </div>
                    <div className={"col-start-7 w-full col-end-10"}>
                        <CustomDropdown
                            LabelFontSize="15px"
                            MinWidth={270}
                            fullWidthBoolean={true}
                            ShowLargeDropdown={true}
                            Height={"44px"}
                            Width={"100%"}
                            handleChange={(e) => {
                                setDataFilter({ ...dataFilter, status: e.target.value });
                            }}
                            value={dataFilter?.status}
                            LargeBorderRadius={true}
                            BgColor={"#FFFFFF"}
                            PlaceholderText={"Status"}
                            MenuList={statusFilter}
                        />
                    </div>
                </div>

                {/* Your content here */}
                {loading ? (
                    <SkeletonLoader rowCount={5} isTable={false} />
                ) : trailMangeData?.length == 0 ? (
                    <div className="mt-4 w-full">
                        <NoDataCard />
                    </div>
                ) : (
                    <InfiniteScroll
                        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-4"
                        dataLength={trailMangeData?.length}
                        next={fetchData}
                        hasMore={trailsFilter?.page <= trailsFilter?.maxPages}
                        loader={
                            spinner && (
                                <div className="flex justify-center">
                                    {" "}
                                    <SpinnerLoader />
                                </div>
                            )
                        }
                        scrollThreshold={0.9} // Adjust this value as needed
                    >
                        {trailMangeData.map((trail, index) => (
                            <TableCard
                                trail={trail}
                                key={index}
                                handleDetails={handleOpenRejectModal}
                                handleButton={handleButton}
                                toggleDrawer={toggleDrawer}
                            />
                        ))}
                    </InfiniteScroll>
                )}
            </div>
            <CustomDrawer open={drawerOpen} toggleDrawer={toggleDrawer}>
                <div className="w-[25rem]">
                    <div className="pl-4 pt-4 pr-4">
                        <HeaderBackNav
                            handleBackNav={() => handleBackDrawer()}
                            TitleFontSize={"18px"}
                            TitleFontWeight={700}
                            Title={drawerData?.title}
                        />
                    </div>
                    <hr />
                    <div className="pl-3 pt-3 pr-3">
                        <CustomSearchbar
                            Placeholder="Search by Name"
                            BgColor="#dfe0eb"
                            value={
                                drawerData?.type == "form"
                                    ? dataFilter?.formSearch
                                    : dataFilter?.piSearch
                            }
                            handleSearch={(value) =>
                                drawerData?.type == "form"
                                    ? setDataFilter({ ...dataFilter, formSearch: value })
                                    : setDataFilter({ ...dataFilter, piSearch: value })
                            }
                        />
                        {drawerData?.type == "pi" ? (
                            <ul className="p-2 cursor-pointer">
                                {drawerData?.loading ? (
                                    <SkeletonLoader rowCount={2} height={"35px"} />
                                ) : drawerData?.data?.length == 0 ||
                                    drawerData?.data == undefined ? (
                                    <div className="mt-4">
                                        <NoDataCard />
                                    </div>
                                ) : (
                                    drawerData?.data?.map((op, index) => (
                                        <li
                                            key={index}
                                            className="flex gap-3 align-center pt-2 pb-2"
                                            style={{ borderBottom: "2px solid #dfe0eb" }}
                                        >
                                            <div className="self-center">
                                                <DoctorIcon />
                                            </div>
                                            <div className="self-center">
                                                <Typography variant="h6">
                                                    {op?.investigator_details?.name}
                                                </Typography>
                                            </div>
                                            <hr />
                                        </li>
                                    ))
                                )}
                            </ul>
                        ) : (
                            <ul className="p-2 self-center cursor-pointer">
                                {drawerData?.loading ? (
                                    <SkeletonLoader rowCount={2} height={"35px"} />
                                ) : drawerData?.data?.length == 0 ||
                                    drawerData?.data == undefined ? (
                                    <div className="mt-4">
                                        <NoDataCard />
                                    </div>
                                ) : (
                                    drawerData?.data?.map((op, index) => (
                                        <li
                                            key={index}
                                            className="flex gap-3 align-center pt-2 pb-2"
                                            style={{ borderBottom: "2px solid #dfe0eb" }}
                                            onClick={() =>
                                                navigate(`form-view/${op?.form_details?.id}`, {
                                                    state: [
                                                        {
                                                            name: "Phase Review Board",
                                                            path: "/phase-review-board",
                                                        },
                                                        {
                                                            name: "Form View",
                                                            path: `${location.pathname}/form-view/${op?.form_details?.id}`,
                                                        },
                                                    ],
                                                })
                                            }

                                        >
                                            <div className="self-center">
                                                <DocumentIcon />
                                            </div>
                                            <div>
                                                <Typography variant="h6">
                                                    {op?.form_details?.title}
                                                </Typography>
                                                <Typography variant="body1" color="textSecondary">
                                                    Type: {op?.form_details?.template_type?.name}
                                                </Typography>
                                            </div>
                                            <hr />
                                        </li>
                                    ))
                                )}
                            </ul>
                        )}
                    </div>
                </div>
            </CustomDrawer>
            <CustomSnackbar
                open={snackbar.value}
                setOpen={dispatch(setSnackbar)}
                color={snackbar?.color}
                message={snackbar?.message}
            />
            <RejectTrialManagementContent
                open={openRejectModal?.open}
                setOpen={setOpenRejectModal}
                modalText={rejectDetails}
                title={openRejectModal?.title}
            />
        </>
    );
}

