import httpService from "./httpService";
const SubjectApi = {
  getSubjectList: (
    phase_id,
    page,
    limit,
    searchData,
    editor_id,
    investigator_id,
    status,
    gender,
    orderBy,
    order
  ) =>
    httpService.get(
      `subjectList?phase_id=${phase_id || ""}&page=${page}&limit=${limit}&searchData=${searchData || ""}&editor_id=${
        editor_id || ""
      }&investigator_id=${investigator_id || ""}&status=[${
        status || ""}]&gender=${gender || ""}&sort_by=${orderBy || ""}&order_by=${order || "asc"}`
    ),
  getSubjectDetails: (id) => httpService.get(`subjectDetails/${id}`),
  setAppointmentAnswerData: (data) =>
    httpService.post(`saveAppointmentAnswerData`, data),
  subjectStatusDropDown: () => httpService.get("subjectStatusDropDown"),
  addSubject: (data) => httpService.post("addSubject", data),
  editPatientId: (data) => httpService.post("updatePatientId", data),

  ///////////////////////addAppointment///////////////////
  addAppointment: (data) => httpService.post("addAppointment", data),
  changeLifeStatus: (data) => httpService.post("changeLifeStatus", data),
  addPatientId: (data) => httpService.post("addPatientId", data)
};
export default SubjectApi;