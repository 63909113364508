import React from "react";
import { BackNavIcon } from "../../assets/common-svg/CommonSvg";
import { useLocation, useNavigate } from "react-router";
import { Typography, useTheme } from "@mui/material";
// import FormTemlateApi from "../../services/form-template";

// formdata

const BreadCrumb = ({BreadCrumbsListing}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const NavigateAndUpdateNavigationState = (path, index) => {
    let UpdatedStateArray = location.state;
    UpdatedStateArray = UpdatedStateArray?.slice(0, index + 1);
    navigate(path, { state: UpdatedStateArray });
  };

  const handleBackNav = () => {
    let UpdatedStateArray = location?.state;

    // let currentpath = location.pathname;
    // let id = currentpath.length > 0 ? currentpath.split('/')[3] : 0;

    // console.log(formData, id)

    // Added code to save as draft if user is back from formbuilder  
    // if(UpdatedStateArray[0]?.name === 'Form Builder' && formData) {
    //   FormTemlateApi.updateFormTemplate(data, rowId)
    // }

    if(UpdatedStateArray){
      UpdatedStateArray?.pop();
      navigate(UpdatedStateArray[(UpdatedStateArray?.length || 0) - 1]?.path, {
        state: UpdatedStateArray,
      });
    } else if(BreadCrumbsListing){
      const lastPath = BreadCrumbsListing[BreadCrumbsListing.length-2]?.path;
      if(BreadCrumbsListing[BreadCrumbsListing.length-2]?.path[0] === '/'){
        navigate(lastPath)
      }
      else{
        navigate(`/${lastPath}`)
      }
    }
    else{
      navigate(-1);
    }
    
    
  };



  return (
    <div className="flex items-center sm:gap-[7px]">
      <div className="cursor-pointer mr-1 sm:mr-3" onClick={handleBackNav}>
        <BackNavIcon />
      </div>
     
      {location.state && location.state.length>0 ?
        location.state?.map((data, index) => {
          return (
            <div key={index} className="flex items-center">
              {index !== location.state?.length - 1 ? (
                <>
                  {" "}
                  <Typography
                    fontWeight={theme.typography.fontWeightRegular}
                    variant="h6"
                    className="cursor-pointer text-xs sm:!text-base capitalize"
                    color="secondary"
                    onClick={() =>
                      NavigateAndUpdateNavigationState(data.path, index)
                    }
                  >
                    {data?.name||""}
                  </Typography>
                  <div className="h-[1px] w-5 rotate-[105deg] bg-black" />
                </>
              ) : (
                <Typography
                  className="text-xs sm:!text-base capitalize"
                  fontWeight={theme.typography.fontWeightRegular}
                  variant="h6"
                >
                  {(data?.name[0].toUpperCase() + data?.name?.slice(1)||"")}
                </Typography>
               
              )}
            </div>
          );
        }):
         BreadCrumbsListing && BreadCrumbsListing.map((breadcrumb,index)=>{
          return  <div key={index} className="flex items-center">
          {index !== BreadCrumbsListing?.length - 1 ? (
            <>
              {" "}
              <Typography
                fontWeight={theme.typography.fontWeightRegular}
                variant="h6"
                className="cursor-pointer text-xs sm:!text-base capitalize"
                color="secondary"
                onClick={() =>{
                  navigate(`/${breadcrumb?.path}`)}
                }
              >
                {breadcrumb?.name || ""}
              </Typography>
              <div className="h-[1px] w-5 rotate-[105deg] bg-black" />
            </>
          ) : (
            <Typography
              className="text-xs sm:!text-base capitalize"
              fontWeight={theme.typography.fontWeightRegular}
              variant="h6"
            >
              {breadcrumb?.name || ""}
            </Typography>
          )}
        </div>
         })
        }

        
    </div>
  );
};

export default BreadCrumb;
