import * as Yup from "yup";
import { Hospital_REGEX, NAME_REGEX } from "../common";

// validation schema login:
export const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
    password: Yup.string().max(255).required("Password is required"),
});

// validation schema forgot password
export const forgotPasswordValidationSchema = Yup.object().shape({
    email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
});

// validation schema create password
export const createNewPasswordValidationSchema = Yup.object().shape({
    password: Yup.string().max(255).required("Password is required").min(6,"Password must be at least 6 characters long."),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Confirm Password is required"),
});
// validation schema add hosptial
export const addHospitalValidationSchema = Yup.object().shape({
  hospitalName: Yup.string()
    .matches(Hospital_REGEX, "Enter a valid Hospital Name")
    .required("Hospital Name is required")
    .min(1, "Hospital Name must be between 1-150 characters long")
    .max(150, "Hospital Name must be between 1-150 characters long"),
  hospitalCode: Yup.string().required("Hospital Code is required"),
  hospitalAddress: Yup.string().required("Hospital Address is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  pincode: Yup.string()
    .required("Pincode is required")
    .min(6, "Pincode must be exactly 6 digits in length")
    .max(6, "Pincode must be exactly 6 digits in length"),
});

// validation schema add hosptial
export const addUserSchema = Yup.object().shape({
  userType: Yup.string().max(255).required("Type is required"),
  name: Yup.string()
    .matches(NAME_REGEX, "Enter a valid Name")
    .required("Name is required")
    .min(1, "Name must be between 1-50 characters long")
    .max(50, "Name must be between 1-50 characters long"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  phoneNumber: Yup.string()
    .matches(/^[6-9]/, "Phone number must start from digits 6-9")
    .max(10, "Phone Number must be 10 digits")
    .min(10, "Phone Number must be 10 digits")
    .required("Phone number is required"),
  hospital: Yup.string()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === null ? "" : value;
    })
    .when("userType", {
      is: (userType) => Number(userType) === 2,
      then: Yup.string().required("Hospital is required"),
      otherwise: Yup.string().notRequired(),
    }),
  trial: Yup.array().when("userType", {
    is: (userType) => {
      const result = Number(userType) === 5;
      return result;
    },
    then: Yup.array()
      .min(1, "At least one Phase is required")
      .required("Phase is required"),
    otherwise: Yup.array()
      .of(Yup.number().required("Each ID must be a number"))
      .notRequired(),
  }),
  principalInvestigator: Yup.number().when("userType", {
    is: (userType) => {
      const result = Number(userType) === 5;
      return result;
    },
    then: Yup.number().required("Principal Investigator is required"),
    otherwise: Yup.number().notRequired(),
  }),
});

// initial values for add Principal-Investigator

export const addPrincipalInvestigatorValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "Principal Name must be between 1-50 characters long")
    .max(50, "Principal Name must be between 1-50 characters long")
    .matches(NAME_REGEX, "Enter a valid Principal Name")
    .required("Principal Investigator Name is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  phone: Yup.string()
    .matches(/^[6-9]/, "Phone number must start from digits 6-9")
    .max(10, "Phone Number must be 10 digits")
    .min(10, "Phone Number must be 10 digits")
    .required("Phone number is required"),
  role_id: Yup.number().required("Role Id is required"),
  hospital: Yup.string().required("Hospital is required"),
});

// validation schema for add Disease-Management
export const addDiseaseManagementValidationSchema = Yup.object().shape({
  diseaseName: Yup.string()
    .matches(NAME_REGEX, "Enter a valid Disease Name")
    .required("Disease Name is required")
    .min(1, "Disease Name must be between 1-150 characters long")
    .max(150, "Disease Name must be between 1-150 characters long"),
  description: Yup.string()
    .max(255, "Description must be at most 255 characters")
    .required("Description is required"),
});

// validation schema add Editor-Management
export const addEditorValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "Name must be between 1-50 characters long")
    .max(50, "Name must be between 1-50 characters long")
    .matches(NAME_REGEX, "Enter a valid Name")
    .required("Name is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  phoneNumber: Yup.string()
    .matches(/^[6-9]/, "Phone number must start from digits 6-9")
    .max(10, "Phone Number must be 10 digits")
    .min(10, "Phone Number must be 10 digits")
    .required("Phone number is required"),
  trial: Yup.array()
    .of(Yup.number().required("Each ID must be a number"))
    .min(1, "Phase is required"),
  principalInvestigator: Yup.number().required(
    "Principal Investigator is required"
  ),
});

export const addNewSubjectValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(1, "Minumimum of 1 character")
    .max(255)
    .required("First Name is required"),
  lastName: Yup.string()
    .min(1, "Minimum of 1 character")
    .max(255)
    .required("Last Name is required"),
  gender: Yup.string().required("Gender is required"),
  dob: Yup.string().required("Date of Birth  is required"),
  disease: Yup.string().required("Disease  is required"),
  formTemplate: Yup.string().max(255),
});

export const addNewSubjectPhaseValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(1, "Minum of 1 character")
    .max(255)
    .required("First Name is required"),
  lastName: Yup.string()
    .min(1, "Minum of 1 character")
    .max(255)
    .required("Last Name is required"),
  gender: Yup.string().required("Gender is required"),
  dob: Yup.string().required("Date of Birth  is required"),
  disease: Yup.string().required("Disease  is required"),
  principal_investigator: Yup.string().required(
    "Principal Investigator  is required"
  ),
  editor_id: Yup.string().required("Editor is required"),
});
export const addFormExportSchema = Yup.object().shape({
  // formId: Yup.string().required('Form is required'),
  // formId: Yup.array().min(1, "Question is required"),
  // questionId: Yup.array().min(1, "Question is required"),
  formId: Yup.array().min(1, "Form is required").required("Form is required"),
  questionId: Yup.array().when("formId", {
    is: (formId) => formId && formId.length > 1,
    then: Yup.array().min(1, "Question is required"),
    otherwise: Yup.array().notRequired(),
  }),
});

// validation schema for edit subject id 
export const validateEditSubjectId = Yup.object().shape({
  patientId: Yup.string().required(
    "Patient Id  is required"
  ),
});

export const ReAssignValidationSchema = Yup.object().shape({
  principal_investigator: Yup.string().required(
    "Principal Investigator  is required"
  ),
  editor_id: Yup.string().required("Editor is required"),
});

export const addProductValidationSchema = Yup.object().shape({
  productName: Yup.string()
    .min(1, "Product name must be between 1-25 character long")
    .max(25, "Product name must be between 1-25 character long")
    .required("Product Name is required"),
  description: Yup.string()
    .max(
      200,
      "Description length must be less than or equal to 200 characters long"
    )
    .required("Description is required"),
});

export const addPhaseValidationSchema = Yup.object().shape({
  title: Yup.string()
    .max(255, "Title cannot be longer than 255 characters")
    .required("Title is required"),
  phase_type: Yup.string().required("Phase Type is required"),
  phase_start_date: Yup.date()
    .typeError("Invalid date format")
    .required("Phase Start Date is required"),
  minimum_subjects: Yup.number()
    .typeError("The number of patient must be a number")
    .min(1, "The minimum number of patients is 1")
    .max(10000, "The maximum number of patients is 10000")
    .required("Minimum number of patients is required"),
  coordinating_PI_id: Yup.string().required("Medical Expert is required"),
  template_type_ids: Yup.array()
    .of(
      Yup.number()
        .typeError("Each ID must be a number")
        .required("At least one Visit type is required")
    )
    .min(1, "At least one Visit type is required")
    .required("Visit type is required"),
  description: Yup.string()
    .max(200, "Description must be at most 200 characters")
    .required("Description is required"),
});

export const addFormTemplateValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(1, "Title must be between 1-150 characters long")
    .max(150, "Title must be between 1-150 characters long")
    .required("Title is required"),
  type: Yup.string().max(255).required("Type is required"),
  description: Yup.string()
    .max(
      255,
      "Description length must be less than or equal to 255 characters long"
    )
    .required("Description is required"),
});

export const ResendRequestValidationSchema = Yup.object().shape({
  description: Yup.string()
    .max(
      200,
      "Description length must be less than or equal to 200 characters long"
    )
    .required("Description is required"),
});

export const rolesValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(1, "Title must be between 1-50 characters long")
    .max(50, "Title must be between 1-50 characters long")
    .required("Title is required"),
  description: Yup.string()
    .max(
      200,
      "Description length must be less than or equal to 200 characters long"
    )
    .required("Description is required"),
});

export const piSubjectValidationSchema = Yup.object().shape({});

export const reAssignPiValidationSchema = Yup.object().shape({
    editor_id: Yup.string().required('Editor is required'),
});

export const updatPhaseValidationSchema = Yup.object().shape({
    phase: Yup.array()
        .of(Yup.number().required("Each ID must be a number"))
        .min(1, "Phase is required"),
});

export const queryMessageValidationSchema = Yup.object().shape({
    phase: Yup.string().required('Phase is required'),
    template: Yup.string().required('Form Builder is required'),
    formField: Yup.string().required('Form Field is required'),
});


export const deathDateSchema = Yup.object().shape({
    deathDateValue: Yup.date()
        .typeError('Invalid date format')
        .required('Date of Death is required'),
});

export const lostToFollowUpSchema = Yup.object().shape({
    lostToFollowUp: Yup.date()
        .typeError('Invalid date format')
        .required('Date of Last Follow Up is required'),
});

export const addPatientIdSchema = Yup.object().shape({
    patientId : Yup.string().required('Patient Id is required'),
});

// validation schema for add form type 
export const addFormTypeSchema = Yup.object().shape({
  typeName: Yup.string()
    .matches(NAME_REGEX, "Enter a valid Form Type Name")
    .required("Form Type Name is required")
    .min(1, "Form Type Name must be between 1-150 characters long")
    .max(150, "Form Type Name must be between 1-150 characters long")
});
