import httpService from "./httpService";

const FormTypeManagementAPI = {
    getAllFormTypes: (limit, page, search) => httpService.get('templateTypeList', {
        params: {
            limit,
            page: page,
            searchData: search,
        }
    }),
    addFormType: (data) => httpService.post(`addTemplateType`, data),
    updateFormType: (data, id) => httpService.post(`updateTemplateType/${id}`, data),
    deleteFormType: (id) => httpService.delete(`deleteTemplateType/${id}`)
}

export default FormTypeManagementAPI;