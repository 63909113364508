import React from "react";
import {
  Stack,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  useTheme,
  TextareaAutosize
} from "@mui/material";
import PropTypes from "prop-types";
const CustomInputField = React.memo(
  ({
    htmlFor,
    label,
    fullWidthBoolean,
    error,
    InputId,
    InputType,
    MultiLine,
    autoSize = false,
    value,
    InputName,
    onBlur,
    onChange,
    NoOfRows,
    iconPosition,
    startIcon,
    endIcon,
    startSecondIcon,
    endSecondIcon,
    switchIcon,
    switchCondition,
    UseIconButton = false,
    handleClickIconButton,
    Size,
    Width,
    handleMouseDownIconButton,
    placeholder,
    helperText,
    UserIconButtonLabel,
    spacing,
    touched,
    handleIconClick,
    FlexGrowBox,
    IsRequired,
    BorderRadius,
    disabled,
    errorClasss,
    CustomClassNameMessage,
    minValueNumber = 0
  }) => {

    const theme = useTheme();
    const trimLeadingWhitespace = (e) => {
      if ([" ", "\n"].includes(e.target.value[0])) {
        e.target.value = e.target.value.substr(1);
      }
    };

    return (
      <div className="relative flex-grow">
        <Stack
          className={`relative ${FlexGrowBox ? "flex-grow" : ""} `}
          spacing={spacing}
        >
          {label && (
            <InputLabel htmlFor={htmlFor} className="text-current static whitespace-normal">
              {label}
              {IsRequired && <span className="text-red-500">*</span>}
            </InputLabel>
          )}

          {MultiLine && autoSize ? (
            <div className="relative">
              <TextareaAutosize
                value={value}
                name={InputName}
                minRows={1}
                onBlur={onBlur}
                onChange={onChange ?? null}
                placeholder={placeholder}
                className={`${CustomClassNameMessage} border border-gray-200 p-2 w-full capitalize`}
                disabled={disabled}
                sx={{
                  width: Width,
                  fontSize: "0.9rem",
                  fontFamily: "roboto",
                  borderRadius: BorderRadius,
                  backgroundColor: disabled
                    ? theme.palette.grey[200]
                    : "white",
                  color: disabled ? theme.palette.primary.light : "black",
                  "& textarea::placeholder": {
                    textTransform: "capitalize",
                  },
                }}
              />
              {endIcon && (
                <div
                  className="absolute bottom-1.5 right-1.5 cursor-pointer hover:bg-gray-300 rounded-sm p-1"
                  onClick={handleIconClick ?? null}
                >
                  {endIcon}
                </div>
              )}
            </div>
          ) : (
            <OutlinedInput
            fullWidth={fullWidthBoolean}
            error={Boolean(error)}
            id={InputId}
            type={
              InputType === "password"
                ? switchCondition
                  ? "text"
                  : "password"
                : InputType
            }
            autoComplete=""
            value={value}
            name={InputName}
            multiline={MultiLine}
            disabled={disabled}
            rows={NoOfRows}
            size={Size ?? "medium"}
            onBlur={onBlur}
            className={`${CustomClassNameMessage} border border-gray-200 capitalize`}
            sx={{
              width: Width,
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "& .MuiInputBase-root.Mui-disabled": {
                "& > fieldset": {
                  color: "red",
                },
              },
              fontSize: "0.9rem !important",
              fontFamily: "roboto",
              height: !MultiLine ? "44px" : "",
              borderRadius: BorderRadius || "6px",
              backgroundColor: disabled ? theme.palette.grey[200] : "white",
              color: disabled ? theme.palette.primary.light : "black",
              position: 'relative',
             "& .MuiInputBase-input::placeholder": {
     textTransform:"capitalize",
    },
            }}
            onFocus={(event) => {
              event.target.setAttribute('autocomplete', 'off');
            }}
            onKeyDown={InputType=="number"?(evt) => (evt.key === 'e' || evt.key === '-' || evt.key === '.') && evt.preventDefault():null}
            onChange={onChange ?? null}
            onInput={trimLeadingWhitespace}
            startAdornment={
              iconPosition === "start" &&
              startIcon && (
                <InputAdornment position="start">
                  {UseIconButton ? (
                    <IconButton
                      aria-label={
                        UserIconButtonLabel ?? "toggle password visibility"
                      }
                      onClick={
                        handleClickIconButton ? handleClickIconButton : null
                      }
                      onMouseDown={
                        handleMouseDownIconButton
                          ? handleMouseDownIconButton
                          : null
                      }
                      edge="end"
                      size="large"
                    >
                      {switchIcon
                        ? switchCondition
                          ? startIcon
                          : startSecondIcon
                        : endIcon}
                    </IconButton>
                  ) : (
                    startIcon
                  )}
                </InputAdornment>
              )
            }
            endAdornment={
              iconPosition === "end" &&
              endIcon && (
                <InputAdornment position="end">
                  {UseIconButton ? (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={
                        handleClickIconButton ? handleClickIconButton : null
                      }
                      onMouseDown={
                        handleMouseDownIconButton
                          ? handleMouseDownIconButton
                          : null
                      }
                      edge="end"
                      size="large"
                    >
                      {switchIcon
                        ? switchCondition
                          ? endIcon
                          : endSecondIcon
                        : endIcon}
                    </IconButton>
                  ) : (
                    <div
                      className="cursor-pointer hover:bg-gray-300 rounded-sm p-1"
                      onClick={handleIconClick ?? null}
                    >
                      {endIcon}{" "}
                    </div>
                  )}
                </InputAdornment>
              )
            }
            placeholder={placeholder}
            inputProps={{
            ...(InputType === "number" && { min: minValueNumber }), // Set min=0 for number type
          }}
          />
          )}
        </Stack>
        {touched && error && (
          <FormHelperText
            error
            id={`standard-weight-helper-text-${InputId}`}
            sx={errorClasss ? {} : { position: "absolute" }}
          >
            {helperText}
          </FormHelperText>
        )}
      </div>
    );
  }
);

CustomInputField.propTypes = {
  htmlFor: PropTypes.string,
  label: PropTypes.string,
  fullWidthBoolean: PropTypes.bool,
  error: PropTypes.bool,
  InputId: PropTypes.string,
  InputType: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  InputName: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  NoOfRows: PropTypes.number,
  iconPosition: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  startSecondIcon: PropTypes.node,
  endSecondIcon: PropTypes.node,
  switchIcon: PropTypes.bool,
  switchCondition: PropTypes.bool,
  UseIconButton: PropTypes.bool,
  handleClickIconButton: PropTypes.func,
  Size: PropTypes.string,
  Width: PropTypes.string,
  handleMouseDownIconButton: PropTypes.func,
  placeholder: PropTypes.string,
  BorderRadius: PropTypes.string,
  helperText: PropTypes.string,
  UserIconButtonLabel: PropTypes.string,
  spacing: PropTypes.number,
  touched: PropTypes.bool,
  handleIconClick: PropTypes.func,
  FlexGrowBox: PropTypes.bool,
  IsRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  errorClasss: PropTypes.bool,
  CustomClassNameMessage: PropTypes.string,
};

export default CustomInputField;
