import { Typography } from '@mui/material';
import { CustomProgressBar } from '../../custom-progress-bar/CustomProgressBar';
import { useState } from 'react';
import { useLocation } from 'react-router';
export const CustomProgressCard = ({ progress = true, title, total, minTotal, icon, percentage,RoleId }) => {
   const location = useLocation();
   const [isSuperAdmin,]=useState(RoleId===1);
   const isProductDetailsPage = location.pathname.startsWith('/products/product-details');

  return (
    <div className="flex w-100 bg-[#FFF9F1] p-2 gap-3 rounded-lg justify-content-between">
      <div className="flex flex-col gap-2">
        {icon}
        {title || total ? <Typography variant="body1" className='font-extrabold'>{title}: {total}</Typography>:""}
        <Typography variant="body1" className='font-extrabold'>{minTotal || 0}</Typography>
      </div>
      {progress && isSuperAdmin && isProductDetailsPage && (
        <div className="self-center">
          <CustomProgressBar percentage={percentage} />
        </div>
      )}
    </div>
  );
}